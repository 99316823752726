export default (context) => {
  function loadScript(src, dataset) {
    return new Promise(function (resolve, reject) {
      var s
      s = document.createElement('script')
      s.src = src
      s.onload = resolve
      s.onerror = reject

      if (dataset) {
        dataset.forEach((data) => {
          s.dataset[data.name] = data.value
        })
      }

      document.head.appendChild(s)
    })
  }

  window.$crisp = window.$crisp || []
  window.$crisp.push(["safe", true])
  loadScript('https://client.crisp.chat/l.js').then(() => {})

}
