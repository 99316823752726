export const state = () => ({
  showSidebar: true,
  availableTools: ['Settings', 'Upload', 'Subtitles', 'Text', 'Elements', 'Details', 'Media'],
  sidebarTools: ['Settings', 'Media', 'Elements', 'Text'],
  activeTool: 'Media',
  selectedSegment: null,
})

export const actions = {
  setActiveTool({ commit, rootGetters, state }, tool) {
    const selectedSegment = rootGetters['editor/video/getSegmentById'](state.selectedSegment);
    if(tool === "Details" && selectedSegment && selectedSegment.type === 'subtitles') {
      commit('setActiveTool', 'Subtitles')
    } else {
      commit('setActiveTool', tool)
  }

  },
  removeActiveToolOnly({commit}) {
    commit('removeActiveToolOnly')
  }
}

export const mutations = {
  setActiveTool(state, tool) {
    if (state.availableTools.includes(tool)) {
      state.activeTool = tool
    }
  },
  setSelectedSegment(state, selectedSegment) {
    state.selectedSegment = selectedSegment
  },
  removeActiveTool(state) {
    state.activeTool = 'Settings'
    state.selectedSegment = null
  },
  removeActiveToolOnly(state) {
    state.activeTool = null
  },
  toggleActiveTool(state, tool) {
    if (state.availableTools.includes(tool)) {
      if (state.activeTool === tool) {
        state.activeTool = null
        state.selectedSegment = null
        return
      }
      state.activeTool = tool
    }
  },
  toggleShowSidebar(state) {
    state.showSidebar = !state.showSidebar
  },
}
