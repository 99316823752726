export default {
  async nuxtServerInit({ dispatch }, ctx) {
    // INFO -> Nuxt-fire Objects can be accessed in nuxtServerInit action via this.$fire___, ctx.$fire___ and ctx.app.$fire___'

    /** Get the VERIFIED authUser on the server */
    if (ctx.res && ctx.res.locals && ctx.res.locals.user) {
      const { allClaims: claims, ...authUser } = ctx.res.locals.user

      console.info('Auth User verified on server-side. User: ', authUser, 'Claims:', claims)

      await dispatch('onAuthStateChanged', {
        authUser,
        claims,
      })
    }
  },

  async onAuthStateChanged({ commit, getters }, { authUser, claims }) {
    if (!authUser) {
      commit('RESET_STORE')
      return
    }
    if (authUser && authUser.getIdToken) {
      try {
        const idToken = await authUser.getIdToken(true)
        // console.info('idToken', idToken)
      } catch (e) {
        console.error(e)
      }
    }

    commit('SET_AUTH_USER', { authUser })
    commit('SET_CLAIMS', { claims })

    if(!this.$fire || !this.$fire.firestore || !this.$fire.firestore.collection) {
      return
    }

    const messageRef = this.$fire.firestore.collection('users').doc(authUser.uid)
    try {
      const snapshot = await messageRef.get()
      const doc = snapshot.data()

      if (!doc) {
        return
      }

      commit('SET_USER_DOC', { userDoc: doc })

      messageRef
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .onSnapshot(async (snapshot) => {
          // In this implementation we only expect one active or trialing subscription to exist.
          if (snapshot.docs.length) {
            const doc = snapshot.docs[0]
            commit('SET_SUBSCRIPTION_DOC', { subscriptionDoc: doc.data() })
            if(this.$posthog) {
              this.$posthog.people.set({ status: getters.isSubscribed })
            }
          } else {
            if(this.$posthog) {
              this.$posthog.people.set({status: getters.isSubscribed})
            }
          }
        })

      if (this.$posthog) {
        this.$posthog.identify(authUser.uid)

        if(doc.email) {
          this.$posthog.people.set({
            email: doc.email,
            admin: doc.admin,
          })
        }
      }

      if(this.$sentry) {
        this.$sentry.setUser({ id: authUser.uid })
      }

      window.$crisp = window.$crisp || []
      if (typeof $crisp !== 'undefined') {
        if (doc.email) {
          $crisp.push(['set', 'user:email', doc.email])
        } else {
          $crisp.push(['set', 'user:nickname', [authUser.uid]])
        }

        let segments = []

        if (authUser.uid) {
          $crisp.push(['set', 'session:data', ['uid', authUser.uid]])
        }

        let video_count = doc.videoCount ? doc.videoCount : 0
        $crisp.push(['set', 'session:data', ['video_count', video_count]])

        if (doc.status) {
          $crisp.push(['set', 'session:data', ['status', doc.status]])
          segments.push(`paid_user_${doc.status}`)
        }

        $crisp.push(['set', 'session:segments', [segments]])
      }
    } catch (e) {
      console.error(e)
    }
  },
  async updateUserDoc({ commit }) {
    const messageRef = this.$fire.firestore.collection('users').doc(this.$fire.auth.currentUser.uid)
    try {
      const snapshot = await messageRef.get()
      const doc = snapshot.data()

      if (!doc) {
        return
      }

      commit('SET_USER_DOC', { userDoc: doc })
    } catch (e) {
      console.error(e)
    }
  },
  async showUpgradeModal({ commit }) {
    if(this.$fire && this.$fire.analytics) {
      this.$fire.analytics.logEvent('open_checkout')
    }
    if (typeof $crisp !== 'undefined') {
      $crisp.push(['set', 'session:event', [[['open_checkout', {}, 'green']]]])
    }
    if (this.$posthog) {
      this.$posthog.capture('open_checkout')
    }
    commit('SET_SHOW_UPGRADE_MODAL', true)
  },
  async hideUpgradeModal({ commit }) {
    commit('SET_SHOW_UPGRADE_MODAL', false)
  },

  checkVuexStore(ctx) {
    if (this.$fire.auth === null) {
      throw 'Vuex Store example not working - this.$fire.auth cannot be accessed.'
    }

    alert('Success. Nuxt-fire Objects can be accessed in store actions via this.$fire___')
  },
}
