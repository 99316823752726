import { render, staticRenderFns } from "./article.vue?vue&type=template&id=45bc90a5&"
import script from "./article.vue?vue&type=script&lang=js&"
export * from "./article.vue?vue&type=script&lang=js&"
import style0 from "./article.vue?vue&type=style&index=0&id=45bc90a5&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBar: require('/opt/buildhome/repo/components/HeaderBar.vue').default,SpeedKitMessage: require('/opt/buildhome/repo/components/SpeedKitMessage.vue').default,FooterSection: require('/opt/buildhome/repo/components/FooterSection.vue').default})
