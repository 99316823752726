import Vue from 'vue'

export default function({ app: { router } }, inject) {

  // loop to wait for window.posthog to be available, then initialize it
  const initPostHog = () => {
    if (window.posthog && window.posthog.__loaded) {
      // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
      inject('posthog', window.posthog)

      // Make sure that pageviews are captured with each route change
      router.afterEach(to => {
        Vue.nextTick(() => {
          /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
          on each page view
          */
          posthog.capture('$pageview', {
            $current_url: to.fullPath
          })
        })
      })
    } else {
      setTimeout(initPostHog, 100)
    }
  }
  initPostHog()

}
